<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
          <ion-buttons slot="start">
            <ion-menu-button color="primary"></ion-menu-button>
          </ion-buttons>
          <ion-title>Cartellino Presenze</ion-title>
      </ion-toolbar>
    </ion-header>  
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
        
        </ion-toolbar>
      </ion-header>
      <div id="container">
        <ion-select v-model="month" style="width:10%">
          <ion-select-option value="01">Gennaio</ion-select-option>
          <ion-select-option value="02">Febbraio</ion-select-option>
          <ion-select-option value="03">Marzo</ion-select-option>
          <ion-select-option value="04">Aprile</ion-select-option>
          <ion-select-option value="05">Maggio</ion-select-option>
          <ion-select-option value="06">Giugno</ion-select-option>
          <ion-select-option value="07">Luglio</ion-select-option>
          <ion-select-option value="08">Agosto</ion-select-option>
          <ion-select-option value="09">Settembre</ion-select-option>
          <ion-select-option value="10">Ottobre</ion-select-option>
          <ion-select-option value="11">Novembre</ion-select-option>
          <ion-select-option value="12">Dicembre</ion-select-option>
        </ion-select>
        <ion-button style="width:5%" @click="searchSelMonth()">
          <ion-icon :icon="searchOutline"></ion-icon>
        </ion-button>
         <ion-button id="export" @click="esportaExcel()" style="width:5%;margin:1%;margin-left:0%;--ion-color-primary:#2c912f">
          <ion-icon :icon="downloadOutline"></ion-icon>
        </ion-button>
        <dtable
          color="#fff"
          bgcolor="rgb(56,128,255)"
          :heading="attendancesHeading"
          :data="attendances"             
          :total="table.total"
          :perPage="table.perPage"
          :currentPage="table.currentPage"
          :orderBy="table.orderBy"
          :order="table.order"
          @changePage="getAllAttendances"
        > 
          <template v-slot:minlav="slotProps">
            {{slotProps.row.minlav/60}}
          </template>  
        </dtable>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
  import { 
    IonButtons,
    IonButton,
    IonContent,
    IonHeader,
    IonIcon,
    IonMenuButton,
    IonPage,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar
  } from '@ionic/vue';
  import Dtable from './partials/Dtable'
  import {downloadOutline,searchOutline} from 'ionicons/icons';


  export default {
    name: 'Folder',
    components: {
      IonButtons,
      IonButton,
      IonContent,
      IonHeader,
      IonIcon,
      IonMenuButton,
      IonPage,
      IonSelect,
      IonSelectOption,
      IonTitle,
      IonToolbar,
      Dtable
    },
    data: function(){
      return{
        attendancesHeading: [         
          {
            text: 'Data',
            code: 'date'
          },
          {
            text: 'Entrata',
            code: 'timbr1'
          },
          {
            text: 'Uscita',
            code: 'timbr2'
          },
          {
            text: 'Entrata',
            code: 'timbr3'
          },
          {
            text: 'Uscita',
            code: 'timbr4'
          },
           {
            text: 'Ore lav.',
            code: 'minlav'
          },
        ],
        table: {
          currentPage: 1,
          total: '',
          perPage: 0,
          orderBy: 'date',
          order: 'asc',
        },
        attendances:[],
        downloadOutline:downloadOutline,
        searchOutline:searchOutline,
        month:0,
        user:{
          id:''
        }
      }
    },
    methods:{
      getUser(){
        this.axios.post(this.apiUrl + 'get-user', {}, this.headers)
        //.then(async (response) => {
        //  console.log(response)
        //})
      },
      getAllAttendances(obj = false){
        if(obj) {
          this.table.currentPage = obj.page
          this.table.orderBy = obj.orderBy
          this.table.order = obj.order
        }
        this.axios.post(this.apiUrl + 'get-all-attendances', { page: this.table.currentPage, orderBy: this.table.orderBy, order: this.table.order}, this.headers)
        .then(async (response) => {
          this.attendances = response.data.attendances.data
          this.table.total = response.data.attendances.total
          this.table.perPage = response.data.attendances.per_page
          this.month=response.data.month

         //console.log(this.month);
        })
      },
      searchSelMonth(obj = false){
        if(obj) {
          this.table.currentPage = obj.page
          this.table.orderBy = obj.orderBy
          this.table.order = obj.order
        }
        this.axios.post(this.apiUrl + 'get-month-attendances', { page: this.table.currentPage, orderBy: this.table.orderBy, order: this.table.order, selMonth:this.month}, this.headers)
        .then(async (response) => {
          this.attendances = response.data.attendances.data
          this.table.total = response.data.attendances.total
          this.table.perPage = response.data.attendances.per_page
          this.month=response.data.month

          //console.log(this.month);
        })
      },
      esportaExcel(){
        window.open('http://backend.hbj-group.com/download-excel-attendances?&user='+this.user.id +'&month='+this.month+'&token='+this.$store.state.apiToken,'_blank')
      },
      getOreLav(){
        this.axios.post(this.apiUrl + 'get-hlav', {}, this.headers)
        //.then(async (response) => {
          //console.log(response)
        //})
      },
      getUserInfo(){
        this.axios.post(this.apiUrl + 'get-userInfo', {}, this.headers)
        .then(async (response) => {
          this.user=response.data.user;
          this.maxDate=response.data.fiveYear;
        })
      },
  }, 
  ionViewWillEnter: function(){
    this.getUserInfo();
    this.getUser();
    this.getAllAttendances();
    this.getOreLav();
  }
}
</script>

<style scoped>
  #container{
    width:90%;
    margin:5%;
  }
  ion-col{
    background-color:#9ea8cc48;
    min-width:40px;
    min-height:60px;
  }
  ion-grid{
    margin-left:10%;
    margin-top:5%;
  }

  ion-button{
    width:100%;
  }
  #header{
    background: darkblue;
    font-weight: bold;
    color:white;
    text-align:center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom:5px solid white;
  }
  #headercol{
    border-right:2px inset rgba(33, 209, 200, 0.479);
  }
  #content{
    border-right:2px inset rgba(33, 209, 200, 0.158);
    border-bottom:2px inset rgba(33, 209, 200, 0.158);
  }
</style>